<template>
  <body>
    <!-- preloader -->
    <Loader> </Loader>
    <!-- preloader -->

    <div class="frame">
      <div class="site-wrapper overflow-hidden">
        <!-- Header Section -->
        <Front-Header> </Front-Header>

        <!-- Main Section -->

        <div class="feature-img-section user-profile"></div>

        <!-- Service section  -->
        <div class="prfInformation">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="st_holder validation">
                  <div class="prfHead">
                    <div class="prfImage">
                      <div class="table">
                        <div class="table_cell">
                          <img v-if="previewImage" v-bind:src="previewImage" alt="tarun" />
                          <img v-else src="../../assets/front/image/profile_img2.jpg" alt="tarun" />
                        </div>
                      </div>
                    </div>
                    <div class="text">
                      <h3>User Profile</h3>
                      <p>
                        Hi {{ item.firstname }}&nbsp;{{ item.lastname }}, Welcome
                        back...1
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section class="package_includes user-profile-option">
          <div class="container">
            <div class="text-center">
              <h2 class="mb-10">Check Your Profile all option in bellow tab</h2>

              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link" :class="{ 'active': toggledOp == 2 }" data-toggle="tab" @click="toggleOptions(2)">My
                    Subscriptions</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{ 'active': toggledOp == 1 }" data-toggle="tab"
                    @click="toggleOptions(1)">Edit Profile</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{ 'active': toggledOp == 3 }" data-toggle="tab" @click="toggleOptions(3)">My
                    Offers</a>
                </li>
              </ul>
              <ul class="nav nav-tabs">
                <li v-if="this.checkAffiliateUser == 1" class="nav-item affiliate-nav affiliateDashbard">
                  <a class="nav-link" style="color:#fff;" href="/affiliate/profile/">Affiliate Dashboard</a>
                </li>
                <li v-if="this.checkAffiliateUser == 0" class="nav-item affiliate-nav affiliateDashbard">
                  <a class="nav-link" style="color:#fff;" href="/affiliate">Affiliate Dashboard</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" style="color:#03e1bc;;" href="/affiliate/instructions">Learn More....</a>
                </li>
              </ul>

              <!-- Tab panes -->
              <div class="tab-content">
                <div class="tab-pane container" :class="{ 'active': toggledOp == 2 }">
                  <section id="entProductSection" class="home_products">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-4 mb-5" v-for="subscription in subscriptions" :key="subscription.id">
                          <div class="product_details">
                            <a href="/en-ae/dubai-2021" onclick="showLoader()" class="d-block" tabindex="0"></a>
                            <div class="top-content N_top-content">
                              <router-link :to="{ path: '/subscriptions/' + subscription.id }" onclick="showLoader()"
                                class="d-block" tabindex="0">
                                <div class="product_img">
                                  <img
                                    data-src="https://etsitecdn.theentertainerme.com/gallery/product2021/DXB+Classic-Product+d.png"
                                    alt="Dubai Classic 2021" class="img-fluid lazyloaded" :src="subscription.image" />
                                </div>
                              </router-link>
                              <router-link :to="{ path: '/subscriptions/' + subscription.id }" tabindex="0">
                                <div class="product_details_inner d-flex flex-column justify-content-center">
                                  <h2 class="product_name m-0">
                                    {{ subscription.title }}
                                  </h2>
                                  <small class="short_desc">{{ subscription.tag_line }}</small>
                                  <div class="product_price">
                                    <div class="tag-wrap">
                                      <span
                                        class="badge_transparent N_special-offer d-inline-flex align-items-center justify-content-center">
                                        Special Offer</span>
                                    </div>
                                    <span class="strike"><span class="before_price">{{subscription.currency}} {{ subscription.price
                                    }}</span></span>
                                    <span class="percentage_off ml-1">
                                      {{ subscription.discount }} % Off
                                    </span>
                                    <h3 class="after_price">
                                      {{subscription.currency}} {{ subscription.discountPrice }}

                                      <span class="vat_info">*{{subscription.currency}} {{ subscription.vat }} including
                                        VAT</span>
                                    </h3>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                            <div class="bottom-content">
                              <div class="d-flex">
                                <ul class="product_features list-unstyled d-flex flex-wrap flex-column p-0">
                                  <li v-for="tag in subscription.tags" :key="tag.value">
                                    <span class="text d-block">{{ tag.value }}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div tabindex="-1" role="dialog" aria-hidden="true" class="modal fade upsellmodal">
                      <div role="document" class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header modal-header-top p-0">
                            <button type="button" data-dismiss="modal" aria-label="Close" class="close">
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <!---->
                        </div>
                      </div>
                    </div>
                    <div tabindex="-1" role="dialog" aria-hidden="true"
                      class="modal fade genericModalConsent separateProduct">
                      <div role="document" class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header modal-header-top p-0">
                            <button type="button" data-dismiss="modal" aria-label="Close" class="close">
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div class="modal-body text-center">
                            <p>
                              You already have products in your basket. Do you
                              wish to remove these and start a new order?
                            </p>
                            <div class="d-flex">
                              <!---->
                              <a href="&amp;cartRefresh=1" class="btn cheersCntBtn subscribe_btn">Yes</a>
                              <a href="javascript:void(0);" data-dismiss="modal"
                                class="btn cheersCntBtn subscribe_btn">No</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button data-toggle="modal" data-target=".upsellmodal" data-keyboard="false" data-backdrop="static"
                      class="tempUpsellBtn" style="display: none"></button>
                    <button data-toggle="modal" data-target=".separateProduct" data-keyboard="false"
                      data-backdrop="static" class="tempSeparateBtn" style="display: none"></button>
                  </section>
                </div>

                <div class="tab-pane container" :class="{ 'active': toggledOp == 1 }">
                  <div class="prfInformation">
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                          <div class="st_holder validation">
                            <div class="form_holder">
                              <div class="forgot-info info-div" style="display: none">
                                <p class="show-error"></p>
                              </div>
                              <div id="errors_key" style="color: red; display: none"></div>
                              <validation-observer ref="simpleRules">
                                <form id="wl-login" class="form-vertical" autocomplete="off" novalidate="novalidate"
                                  v-on:submit.prevent="validationForm">
                                  <input type="hidden" name="_csrf"
                                    value="R3M1R2d4a2soPwR/CjQHUiA8DXRVGg05KisFLjU3GB8BMlYPNQwxIw==" />
                                  <fieldset>
                                    <div class="cn_block inf_holder">
                                      <div class="title_head">
                                        <h2>Personal Information</h2>
                                      </div>
                                      <div class="wrapFields">
                                        <div class="fl_row">
                                          <div class="form-group">
                                            <label for="firstname">First Name</label>
                                            <validation-provider name="First Name" rules="required|max:20"
                                              #default="{ errors }">
                                              <input name="firstname" placeholder="First Name" id="firstname"
                                                class="input_field" type="text" v-model="item.firstname" />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                          </div>
                                        </div>
                                        <div class="fl_row">
                                          <div class="form-group">
                                            <label for="lastname">Last Name</label>
                                            <validation-provider name="First Name" rules="required|max:20"
                                              #default="{ errors }">
                                              <input name="lastname" placeholder="Last Name" id="lastname"
                                                class="input_field" type="text" v-model="item.lastname" />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                          </div>
                                        </div>

                                        <div class="fl_row">
                                          <div class="form-group">
                                            <label for="email">E-mail</label>
                                            <validation-provider name="Email"
                                              rules="required|email|uniqueEmailExceptOld|max:50" #default="{ errors }">
                                              <input type="text" name="email" id="email" class="input_field"
                                                v-model="item.email" />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                          </div>
                                        </div>

                                        <div class="fl_row">
                                          <div class="form-group">
                                            <label for="phone">Phone Number</label>
                                            <validation-provider name="Phone Number" rules="max:20" #default="{ errors }">
                                              <input type="text" name="phone" id="phone" class="input_field"
                                                v-model="item.phone" />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                          </div>
                                        </div>
                                        <div class="fl_row">
                                          <div class="form-group">
                                            <label for="country">Country of Residence</label>
                                            <div class="select_holder" v-if="item.country != ''">
                                              <validation-provider name="Country of Residence" rules=""
                                                #default="{ errors }">
                                                <select id="country" name="country" v-model="item.country"
                                                  aria-required="true" aria-invalid="false" class="input_field">
                                                  <option v-for="(option) in optionsCountry" v-bind:key="option.name"
                                                    v-bind:value="option.id">
                                                    {{ option.name }}
                                                  </option>
                                                </select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                              </validation-provider>
                                            </div>
                                            <div class="select_holder" v-else>
                                              <validation-provider name="Country of Residence" rules=""
                                                #default="{ errors }">
                                                <select id="country" name="country" v-model="item.country"
                                                  aria-required="true" aria-invalid="false"
                                                  @change="confirmPopup(item.country)" class="input_field">
                                                  <option v-for="(option) in optionsCountry" v-bind:key="option.name"
                                                    v-bind:value="option.id">
                                                    {{ option.name }}
                                                  </option>
                                                </select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                              </validation-provider>
                                            </div>
                                          </div>
                                          <div class="fl_row">
                                            <div class="form-group">
                                              <label for="nationality">Nationality</label>
                                              <div class="select_holder">
                                                <validation-provider name="Nationality" rules="" #default="{ errors }">
                                                  <select id="nationality" name="nationality" v-model="item.nationality"
                                                    aria-invalid="false" class="input_field">
                                                    <option v-for="(option) in optionsCountry" v-bind:key="option.name"
                                                      v-bind:value="option.id">
                                                      {{ option.name }}
                                                    </option>
                                                  </select>
                                                  <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                              </div>
                                            </div>
                                            <div class="fl_row">
                                              <div class="form-group">
                                                <label for="image" class="imageLable">Image</label>
                                                <validation-provider name="Image" rules="image|size:100"
                                                  #default="{ errors }">
                                                  <b-form-file id="image" v-model="item.profileImg" ref="fileInput"
                                                    @change="uploadImage" />
                                                  <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                              </div>
                                            </div>
                                            <!-- Profile Preview -->

                                            <b-col cols="12">
                                              <b-img thumbnail fluid :src="previewImage"
                                                :class="previewImage.length <= 0 ? 'd-none' : null" alt="Image"></b-img>
                                            </b-col>

                                            <input type="submit" class="link btnWhite btn btn-primary button_wraper" value="Submit"
                                              style="margin-top: 30px" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </fieldset>
                                </form>
                              </validation-observer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane container" :class="{ 'active': toggledOp == 3 }">
                  <section class="all-offers-section">
                    <div id="contactFeature" class="contactFeature">
                      <div class="py-5 service-11">
                        <div class="container">
                          <div class="row why-chose-us-row">
                            <div class="col-md-12">
                              <div class="row">
                                <div id="content" class="col-md-6 wrap-service11-box reusable" v-for="offer in offers"
                                  :key="offer.id">
                                  <router-link :to="{ path: '/offer-detail/' + offer.id }">
                                    <div class="card card-shadow border-0 mb-4">
                                      <div class="p-4" style="">
                                        <div class="icon-space">
                                          <div
                                            class="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                                            <img alt="PizzaExpress Dubai" class="lazyloaded" :data-src="offer.image"
                                              :src="offer.image" />

                                            <div v-if="offer.is_reuseable">
                                              <svg class="lock-svg-icon" width="461.4px" height="461.4px"
                                                enable-background="new 0 0 461.404 461.404" version="1.1"
                                                viewBox="0 0 461.404 461.404" xml:space="preserve"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                  d="m368.15 195.83h-4.202l8.199-59.771c4.343-31.699-3.909-63.19-23.258-88.674-19.339-25.484-47.442-41.916-79.137-46.26-40.15-5.505-80.041 9.477-106.68 40.092-8.185 9.402-7.19 23.651 2.202 31.837 9.412 8.175 23.661 7.196 31.848-2.205 16.604-19.092 41.466-28.438 66.506-25.013 40.762 5.592 69.402 43.316 63.809 84.094l-9.04 65.894h-225.16c-9.59 0-17.36 7.771-17.36 17.357v230.87c0 9.581 7.77 17.356 17.36 17.356h274.92c9.589 0 17.359-7.77 17.359-17.356v-230.86c-5e-3 -9.586-7.775-17.356-17.365-17.356zm-117.02 172.97v64.199h-42.386v-65.945c-3.844-4.805-6.153-10.876-6.153-17.51 0-15.517 12.585-28.099 28.108-28.099 15.518 0 28.104 12.586 28.104 28.099-1e-3 7.46-2.934 14.222-7.673 19.256z" />
                                              </svg>
                                            </div>
                                            <div v-else>
                                              <svg class="lock-svg-icon" enable-background="new 0 0 512 512" version="1.1"
                                                viewBox="0 0 512 512" xml:space="preserve"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                  d="m437.33 192h-32v-42.667c0-82.343-66.989-149.33-149.33-149.33s-149.33 66.99-149.33 149.33v42.667h-32c-5.896 0-10.667 4.771-10.667 10.667v266.67c0 23.531 19.135 42.666 42.667 42.666h298.67c23.531 0 42.666-19.135 42.666-42.667v-266.67c0-5.896-4.771-10.667-10.667-10.667zm-149.4 222.82c0.333 3.01-0.635 6.031-2.656 8.292-2.021 2.26-4.917 3.552-7.948 3.552h-42.667c-3.031 0-5.927-1.292-7.948-3.552s-2.99-5.281-2.656-8.292l6.729-60.51c-10.927-7.948-17.458-20.521-17.458-34.313 0-23.531 19.135-42.667 42.667-42.667s42.667 19.135 42.667 42.667c0 13.792-6.531 26.365-17.458 34.313l6.728 60.51zm53.395-222.82h-170.67v-42.667c0-47.052 38.281-85.333 85.333-85.333s85.333 38.281 85.333 85.333v42.667z" />
                                              </svg>
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <div class="entry-category">
                                            <a href="#" rel="category" tabindex="0" v-if="offer.is_reuseable">Reusable</a>
                                          </div>
                                          <h4 class="font-weight-medium company-name mt-5">
                                            {{ offer.title }}
                                          </h4>
                                          <h6 class="font-weight-medium mt-0">
                                            {{ offer.company_name }}
                                          </h6>
                                          <p class="mt-3">
                                            {{ offer.currency }} {{ offer.amount }} Estimated
                                            savings
                                          </p>
                                          <span><i class="fas fa-clock" style="margin-right: 5px"></i>Valid until
                                            {{ offer.valid_date | validDate }}</span>
                                          <div></div>
                                        </div>
                                      </div>
                                      <ul class="offer_list2 mt-4">
                                        <li v-for="tag in offer.tag" :key="tag">
                                          <span class="offer_text">{{ tag }}</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Main Section -->
        <!-- Footer Section -->
        <Front-Footer> </Front-Footer>
      </div>
    </div>
  </body>
</template>

<script>

import Header from './Front-Header';
import Footer from './Front-Footer';
import Loader from './Loader';
import VueMeta from 'vue-meta';
import Vue from 'vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vue.use(VueMeta)

import { db, storage } from '@/main'

import {
  BFormFile,
} from 'bootstrap-vue'

//Vee-Validate 
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
  required, email, confirmed, password, image, size, uniqueEmailExceptOld
} from '@validations'

export default {
  name: 'Profile',
  data() {
    return {
      previewImage: '',
      item: {},
      selected: '',
      optionsCountry: [],
      options: [],
      previewImage: '',//https://www.theentertainerme.com/images/profile_img2.jpg
      subscriptions: [],
      offers: [],
      toggledOp: 1,
      checkAffiliateUser: ''
    }
  },
  components: {
    'Front-Header': Header,
    'Front-Footer': Footer,
    'Loader': Loader,
    ValidationProvider,
    ValidationObserver,
    validate,
    required,
    email,
    confirmed,
    password,
    image,
    size,
    uniqueEmailExceptOld,
    BFormFile

  },
  metaInfo() {
    //alert();
    return {
      title: "Profile",
      meta: [
        { name: 'description', content: 'Profile' },
      ]
    }
  },

  created: function () {
    this.getItem();
    this.getCountries();
    this.getCategories();
    this.fetchSubscriptions();
    this.getOffers();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },

  methods: {

    confirmPopup: function (country) {
      db.collection('countries').doc(country).get()
        .then((doc) => {
          console.log(doc.data().country);
          if (confirm("Do you really want to select " + doc.data().country + " country?")) {
          } else {
            this.item.country = '';
            console.log(this.item.country);
          }
        })
    },

    toggleOptions(val) {
      this.toggledOp = val;
    },

    uploadImage(event) {
      validate(event.target.files[0], 'image|size:100', {
        name: 'Profile Image',
      }).then(result => {
        if (result.valid) {
          const fileReader = new FileReader();

          fileReader.onload = (event) => {
            this.previewImage = event.target.result;
          }
          fileReader.readAsDataURL(event.target.files[0]);
        } else {
          this.previewImage = null;
        }
      });
    },

    getItem() {

      var id = localStorage.getItem("userSessionId");
      console.log('sssss ' + id);
      console.log('sssss');
      //var id = id;
      //var id = this.$route.params.id;

      /*db.collection('blogs').doc(id).get().then((doc) => {
          console.log(doc.data());
          this.item = doc.data();
          this.previewImage = doc.data().image;
      })*/
      console.log('fssdfs');
      console.log(id);


      //db.collection("users").where("id", "==", id)
      //.get()
      db.collection('users').doc(id).get()
        .then((doc) => {

          console.log('dddd');

          console.log(doc.data());
          this.item = doc.data();
          this.previewImage = doc.data().image;
          if (!doc.data().affiliateStatus || doc.data().affiliateStatus == 0) {
            this.checkAffiliateUser = 0;
          } else {
            this.checkAffiliateUser = 1;
          }
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

    },

    getCountries() {
      this.optionsCountry = [];

      // alert()
      db.collection("countries")
        .orderBy("country", "asc")
        // .orderBy('country', 'asc')
        .get()
        .then((querySnapshot) => {
          //console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            this.optionsCountry.push({
              id: doc.id,
              name: doc.data().country,
              slug: doc.data().slug,
            });
            console.log(doc.id, " => ", doc.data());
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    getCategories() {
      this.options = [];

      db.collection("categories")
        .get()
        .then((querySnapshot) => {
          //console.log('hhhh');
          querySnapshot.forEach((doc) => {
            this.options.push({
              id: doc.id,
              name: doc.data().name,
            });
            //console.log(doc.id, " => ", doc.data());

          });

          //console.log(this.options);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },

    validationForm() {

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submit();
        }
      })
    },

    submit() {
      if (confirm("Do you really want to update profile")) {
        var id = localStorage.getItem("userSessionId");
        var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
        console.log(this.item);
        db.collection("users")
          .doc(id)
          .update({
            firstname: this.item?.firstname,
            lastname: this.item?.lastname,
            phone: this.item?.phone,
            email: this.item?.email,
            country: this.item?.country,
            nationality: this.item?.nationality,
            modified: date,
          })
          .then(() => {
            console.log("Added Successfully!");
            if (this.item.profileImg) {
              const filename = this.item.profileImg.name;
              console.log(filename);
              const ext = filename.slice(filename.lastIndexOf('.'));
              const storageRef = storage.ref();
              const fileRef = storageRef.child('users/' + id + "." + ext);
              fileRef.put(this.item.profileImg).then(result => {
                fileRef.getDownloadURL().then(imageUrl => {
                  console.log(imageUrl);
                  db.collection("users").doc(id).update({ image: imageUrl })
                  this.showNotification('Success, Updated Successfully!', 'UserIcon', 'success');
                }).catch((error) => {
                  console.error("Error writing document: ", error);
                  this.showNotification('Error! Request Failed!', 'UserIcon', 'danger');
                });
              });
            }
          })
          .then(() => {
            this.showNotification('Success, Updated Successfully!', 'UserIcon', 'success');
          })
          .catch((error) => {
            this.showNotification('Error updating document!', 'UserIcon', 'danger');

          });
      } else {

      }
    },

    fetchSubscriptions() {
      var id = localStorage.getItem("userSessionId");
      /* debuf */
      db.collection("order_details")
        .where("uid", "==", id)
        // .where("delete_id", "!=", 1)
        //.orderBy("created", "desc")
        .get()
        .then((querySnapshot) => {
          console.log('hhhh');

          querySnapshot.forEach((doc1) => {

            console.log(doc1.id, " => ", doc1.data());

            db.collection("subscriptions")
              .get()
              .then((querySnapshot) => {
                console.log('hhhh');

                querySnapshot.forEach((doc) => {

                  console.log("doc1.data().subscriptions");
                  console.log(doc1.data().subscription);
                  console.log(doc.id);
                  if (doc1.data().subscription == doc.id && doc1.data().delete_id != 1) {
                    var discountPrice = doc.data().price - doc.data().price * doc.data().discount / 100;
                    var vat = discountPrice + discountPrice * doc.data().vat / 100;
                    this.subscriptions.push({
                      id: doc.id,
                      title: doc.data().title,
                      tag_line: doc.data().tag_line,
                      tags: doc.data().tag,
                      currency: doc.data().currency,
                      description: doc.data().description,
                      image: doc.data().image,
                      price: doc.data().price,
                      discount: doc.data().discount,
                      discountPrice: discountPrice,
                      vat: vat,
                      created: doc.data().created,
                    });
                  }


                });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              });
            /*tsy */


          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
      /* debuf */


    },

    getOffers() {
      var uid = localStorage.getItem("userSessionId");

      db.collection('redeem_offers')
        .where("uid", "==", uid)
        .get().then((querySnapshot) => {
          querySnapshot.forEach((docs) => {

            db.collection('offers')
              //.where("uid", "==", uid)
              .doc(docs.data().offer_id)
              .get().then((doc) => {

                db.collection('companies')
                  //.where("uid", "==", uid)
                  .doc(doc.data().company)
                  .get().then((res) => {

                    this.offers.push({
                      id: doc.id,
                      title: doc.data().title,
                      company_name: res.data().name,
                      amount: doc.data().amount,
                      currency: doc.data().currency,
                      is_reuseable: doc.data().is_reuseable,
                      tag: doc.data().tag,
                      valid_date: doc.data().valid_date,
                      image: doc.data().image,

                    });
                  });
              });
          })

        })


    },

    showNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },

  }
}
</script>

<style scoped>
span {
  width: 100%;
}

[dir] .prfInformation .st_holder .inf_holder .fl_row .input_field {
  margin-bottom: 2px;
}

.affiliate-nav {
  background: #03e1bc !important;
  border-radius: 50px;
}

.affiliate-nav {}

.affiliateDashbard{
  background: #f88300!important;
}
</style>
